<template>
  <div class="customer-profiling-details-bets">
    <CustomerProfilingDetailsBetsHeader />
    <CustomerProfilingDetailsBetsTable />
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import CustomerProfilingDetailsBetsHeader from './CustomerProfilingDetailsBetsHeader';
import CustomerProfilingDetailsBetsTable from './CustomerProfilingDetailsBetsTable';

export default {
  components: {
    CustomerProfilingDetailsBetsHeader,
    CustomerProfilingDetailsBetsTable,
  },
  setup() {
    const store = useStore();

    const customerBets = computed(() => store.getters['betTicker/customerBetsData']);

    return {
      customerBets,
    };
  },
};
</script>

<style lang="scss">
.customer-profiling-details-bets {
  background-color: #fff;
  width: 100%;
  border-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  overflow: hidden;
}
</style>
