<template>
  <div
    :class="[
      'customer-profiling-details-bets-table-cell',
      'customer-profiling-details-bets-table-cell--is-selection-cell',
      { 'customer-profiling-details-bets-table-cell--is-multi': multiSelection },
    ]"
  >
    <span class="customer-profiling-details-bets-table-cell__text">
      {{ displayValue }}
    </span>
  </div>
</template>

<script>
import { computed } from 'vue';
import {
  includes,
  startCase,
  toLower,
  replace,
} from 'lodash';
import MarketMapper from '@/services/helpers/market-mapper';

export default {
  props: {
    columnKey: {
      type: String,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
    bet: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const setTeamNames = (selectionLabel, homeTeam, awayTeam) => {
      let replacedLabel = startCase(toLower(selectionLabel));
      if (includes(replacedLabel, 'Home') && replacedLabel !== 'Home Run') {
        replacedLabel = replace(replacedLabel, /Home/g, homeTeam?.name);
      }
      if (includes(replacedLabel, 'Away')) {
        replacedLabel = replace(replacedLabel, /Away/g, awayTeam?.name);
      }

      return replacedLabel;
    };

    const selectionName = ({
      marketCode, selection, isUsaView, competitors, sport,
    }) => {
      if (marketCode === 'PLAYER_POINT_BANDS') {
        return MarketMapper.selectionName(selection, sport, isUsaView);
      }
      const selectionNameLabel = MarketMapper.selectionName(selection, sport, isUsaView);
      return setTeamNames(selectionNameLabel, competitors?.[0], competitors?.[1]);
    };

    const displayLine = ({ params, marketCode, selection }) => {
      const line = params.LINE;
      if (!line) return '';
      if (!includes(marketCode, 'HANDICAP')) return ` ${line}`;
      let lineValue = '';
      lineValue = line;
      if (selection.selectionType.selectionCode === 'HOME' && line > 0) {
        lineValue = ` +${line}`;
      }
      if (selection.selectionType.selectionCode === 'AWAY' && line < 0) {
        lineValue = ` +${Math.abs(line)}`;
      }
      if (selection.selectionType.selectionCode === 'AWAY' && line > 0) {
        lineValue = ` -${line}`;
      }
      return lineValue;
    };

    const parlayColumns = ['selection', 'eventId'];
    const isParlayColumn = computed(() => props.bet.multibet && includes(parlayColumns, props.columnKey));
    const multiSelection = computed(() => props.bet.betPartsCount > 1);
    const displayValue = computed(() => {
      if (isParlayColumn.value) {
        return props.bet.betPartsCount;
      }
      return `${selectionName({
        marketCode: props.bet.market,
        selection: props.bet.selection,
        isUsaView: props.bet.isUsaView,
        competitors: props.bet.competitors,
        sport: props.bet.sport,
      })}${displayLine({ params: props.bet.marketParams, marketCode: props.bet.market, selection: props.bet.selection })}`;
    });

    return {
      multiSelection,
      displayValue,
    };
  },
};
</script>

<style lang="scss">
.customer-profiling-details-bets-table-cell {
  &--is-selection-cell {
    background: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    &__text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }
  }

  &--is-multi {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 100%;
    width: 25px;
    height: 25px;
  }
}
</style>
