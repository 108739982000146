<template>
  <div class="customer-profiling-details-bets-table-cell customer-profiling-details-bets-table-cell--is-flag-cell">
    <Icon
      v-if="!bet.isBetPart"
      :name="isFlagged ? 'flag-fill' : 'flag'"
      @click.stop="setIsFlagged(!isFlagged)"
    />
  </div>
</template>

<script>
import { get } from 'lodash';
import { computed } from 'vue';
import { useStore } from 'vuex';
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    columnKey: {
      type: String,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
    bet: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const isFlagged = computed(() => get(props.bet, props.field));

    const setIsFlagged = (newIsFlagged) => {
      store.dispatch('betTicker/applyCustomerBetFlag', {
        betId: props.bet.betId,
        isFlagged: newIsFlagged,
      });
    };

    return {
      isFlagged,
      setIsFlagged,
    };
  },
};
</script>

<style lang="scss">
.customer-profiling-details-bets-table-cell {
  &--is-flag-cell {
    display: flex;

    .icon {
      width: 24px;
      height: 24px;
      padding: 4px;
      cursor: pointer;
    }
  }
}
</style>
