<template>
  <div
    :class="[
      'customer-profiling-details-bets-table-cell',
      'customer-profiling-details-bets-table-cell--is-potential-payout-cell',
    ]"
  >
    <span class="customer-profiling-details-bets-table-cell__text">
      {{ displayValue }}
    </span>
  </div>
</template>

<script>
import { get, isNil } from 'lodash';
import { computed } from 'vue';

function formatNumberWithDots(number) {
  return number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export default {
  props: {
    columnKey: {
      type: String,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
    bet: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const displayValue = computed(() => {
      const value = get(props.bet, props.field);
      return isNil(value) ? '-' : `$${formatNumberWithDots(value)}`;
    });

    return {
      displayValue,
    };
  },
};
</script>

<style lang="scss">
.customer-profiling-details-bets-table-cell {
  &--is-potential-payout-cell {
    background: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    text-align: right;

    &__text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }
  }
}
</style>
