<template>
  <div class="customer-profiling-details-bets-table-cell customer-profiling-details-bets-table-cell--is-toggle-cell">
    <Icon
      v-if="isMultiBet"
      :name="bet.toggled ? 'chevron-down' : 'chevron-right'"
      @click.stop="toggleMultiBet"
    />
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    columnKey: {
      type: String,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
    bet: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const isMultiBet = computed(() => props.bet.multibet);

    const toggleMultiBet = () => {
      store.dispatch('betTicker/toggleCustomerProfilingDetailsParlayBet', props.bet.betId);
    };

    return {
      isMultiBet,
      toggleMultiBet,
    };
  },
};
</script>

<style lang="scss">
.customer-profiling-details-bets-table-cell {
  &--is-toggle-cell {
    display: flex;

    .icon {
      stroke: #000;
      width: 24px;
      height: 24px;
      padding: 4px;
      flex-shrink: 0;
      cursor: pointer;
    }
  }
}
</style>
