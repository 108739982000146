<template>
  <div
    :class="[
      'customer-profiling-details-bets-table-cell',
      'customer-profiling-details-bets-table-cell--is-odds-cell',
    ]"
  >
    <span class="customer-profiling-details-bets-table-cell__text">
      {{ displayValue }}
    </span>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import OddsConverter from '@/services/helpers/odds-converter';

export default {
  props: {
    columnKey: {
      type: String,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
    bet: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const selectedOddFormat = computed(() => store.getters.selectedOddFormat?.name);
    const displayOdd = (price) => {
      const americanOdd = price?.originalFormattedValue;
      if (americanOdd && selectedOddFormat.value === 'american') return `${americanOdd.signIsPlus ? '+' : '-'}${americanOdd.value}`;
      if (selectedOddFormat.value === 'probability') {
        const probability = parseFloat((price.probability + price.bias) * 100).toFixed(1);
        return (probability % 1 > 0) ? `${probability}%` : `${Math.round(probability)}%`;
      }
      return OddsConverter.getMappedOddValue(price, selectedOddFormat.value);
    };
    const displayValue = computed(() => displayOdd(props.bet.odds));

    return {
      displayValue,
    };
  },
};
</script>

<style lang="scss">
.customer-profiling-details-bets-table-cell {
  &--is-odds-cell {
    background: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    text-align: right;

    &__text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }
  }
}
</style>
