<template>
  <div
    :class="[
      'customer-profiling-details-bets-table-cell',
      'customer-profiling-details-bets-table-cell--is-market-cell',
    ]"
  >
    <span class="customer-profiling-details-bets-table-cell__text">
      {{ displayValue }}
    </span>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import MarketMapper from '@/services/helpers/market-mapper';

export default {
  props: {
    columnKey: {
      type: String,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
    bet: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const displayValue = computed(() => {
      const marketDisplayConfiguration = store.getters['betTicker/marketDisplayConfigurationByIds']({
        sportId: props.bet.sportId, marketCode: props.bet.market, selections: props.bet.marketParams?.SELECTIONS,
      });
      if (!marketDisplayConfiguration) return props.bet.market;
      return MarketMapper.mapBetTickerMarketName({
        marketParams: props.bet.marketParams,
        marketSummary: props.bet.marketSummary,
        marketDisplayConfiguration,
        homeTeam: props.bet.competitors[0] || {},
        awayTeam: props.bet.competitors[1] || {},
      });
    });

    return {
      displayValue,
    };
  },
};
</script>

<style lang="scss">
.customer-profiling-details-bets-table-cell {
  &--is-market-cell {
    background: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    &__text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }
  }
}
</style>
