<template>
  <div
    :class="[
      'customer-profiling-details-bets-table-cell',
      'customer-profiling-details-bets-table-cell--is-competition-cell',
      { 'customer-profiling-details-bets-table-cell--is-multi': multiCompetition },
    ]"
  >
    <span class="customer-profiling-details-bets-table-cell__text">
      {{ displayValue }}
    </span>
  </div>
</template>

<script>
import { get, isNil } from 'lodash';
import { computed } from 'vue';

export default {
  props: {
    columnKey: {
      type: String,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
    bet: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const uniqueCompetitions = computed(() => [...new Set(props.bet.betParts?.map((betPart) => betPart.competitionName))]);
    const multiCompetition = computed(() => uniqueCompetitions.value.length > 1);
    const displayValue = computed(() => {
      if (multiCompetition.value) {
        return multiCompetition.value
          ? uniqueCompetitions.value.length
          : props.betcompetitionName;
      }
      const value = get(props.bet, props.field);
      return isNil(value) ? '-' : value;
    });

    return {
      multiCompetition,
      displayValue,
    };
  },
};
</script>

<style lang="scss">
.customer-profiling-details-bets-table-cell {
  &--is-competition-cell {
    background: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    &__text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }
  }

  &--is-multi {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 100%;
    width: 25px;
    height: 25px;
  }
}
</style>
