<template>
  <div class="customer-profiling-details-bets-header">
    <div class="customer-profiling-details-bets-header__header">
      Placed bets
    </div>
    <div class="customer-profiling-details-bets-header__section">
      <div class="customer-profiling-details-bets-header__section-group">
        <CustomerProfilingDetailsBetsHeaderActiveFilter
          :model-value="isActive"
          @update:model-value="setIsActive"
        />
        <CustomerProfilingDetailsBetsHeaderFlaggedFilter
          :model-value="isFlagged"
          @update:model-value="setIsFlagged"
        />
        <Button
          icon="filters"
          @click="openFiltersSidebar"
        >
          Filters
        </Button>
      </div>
      <div
        class="customer-profiling-details-bets-header__section-separator"
      />
      <Button
        icon="link-external"
        @click="openBetTicker"
      >
        Bet ticker
      </Button>
    </div>
  </div>
</template>

<script>
import { cloneDeep, find, filter } from 'lodash';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import {
  FilterCondition,
  serializeFilterQuery,
} from '@/services/helpers/filters';
import {
  CustomerDetailsSidebar,
  CustomerDetailsFilter,
} from '@/services/helpers/customer-profiling';
import Button from '@/components/common/Button';
import CustomerProfilingDetailsBetsHeaderActiveFilter from './CustomerProfilingDetailsBetsHeaderActiveFilter';
import CustomerProfilingDetailsBetsHeaderFlaggedFilter from './CustomerProfilingDetailsBetsHeaderFlaggedFilter';

export default {
  components: {
    Button,
    CustomerProfilingDetailsBetsHeaderActiveFilter,
    CustomerProfilingDetailsBetsHeaderFlaggedFilter,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const isLoading = computed(() => store.getters['betTicker/customerBetsIsLoading']);
    const allFilters = computed(() => store.getters['betTicker/customerBetsFilters']);

    const isActive = computed(() => find(allFilters.value, ({ id: CustomerDetailsFilter.ACTIVE }))?.value);
    const setIsActive = (newIsActive) => {
      const newFilters = filter(allFilters.value, (filterObject) => filterObject.id !== CustomerDetailsFilter.ACTIVE);
      if (newIsActive) {
        newFilters.push({
          id: CustomerDetailsFilter.ACTIVE,
          condition: {
            id: FilterCondition.EQUAL,
          },
          value: true,
        });
      }
      const { page, ...query } = cloneDeep(route.query);
      query.filter = serializeFilterQuery(newFilters, {
        oddsFormat: store.getters.selectedOddFormat?.id || '',
      });
      router.replace({
        ...route,
        query,
      });
    };

    const isFlagged = computed(() => find(allFilters.value, ({ id: CustomerDetailsFilter.FLAGGED }))?.value);
    const setIsFlagged = (newIsFlagged) => {
      const newFilters = filter(allFilters.value, (filterObject) => filterObject.id !== CustomerDetailsFilter.FLAGGED);
      if (newIsFlagged) {
        newFilters.push({
          id: CustomerDetailsFilter.FLAGGED,
          condition: {
            id: FilterCondition.EQUAL,
          },
          value: true,
        });
      }
      const { page, ...query } = cloneDeep(route.query);
      query.filter = serializeFilterQuery(newFilters, {
        oddsFormat: store.getters.selectedOddFormat?.id || '',
      });
      router.replace({
        ...route,
        query,
      });
    };

    const openFiltersSidebar = () => {
      store.dispatch('betTicker/setCustomerSidebar', CustomerDetailsSidebar.FILTERS);
    };
    const openBetTicker = () => {
      const url = router.resolve({ name: 'bet-ticker' }).href;
      window.open(url, '_blank');
    };

    return {
      isLoading,
      isActive,
      setIsActive,
      isFlagged,
      setIsFlagged,
      openFiltersSidebar,
      openBetTicker,
    };
  },
};
</script>

<style lang="scss">
.customer-profiling-details-bets-header {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
  height: 56px;
  min-height: 56px;
  max-height: 56px;
  padding: 0 16px;
  border-bottom: 1px solid #F0F0F0;
  overflow-x: auto;

  &__header {
    color: #191414;
    font-family: 'Rubik', sans-serif;
    font-weight: 600;
    font-size: 15.72px;
    line-height: 23.58px;
  }

  &__section {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 100%;
    padding: 12px 0;

    &-group {
      display: flex;
      align-items: center;
      gap: 16px;
      height: 100%;
    }

    &-separator {
      background-color: #F0F0F0;
      width: 1px;
      min-width: 1px;
      max-width: 1px;
      height: 100%;
    }
  }
}
</style>
